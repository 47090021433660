import { writable, get } from 'svelte/store';

const routes = (function() {
	const opts = {
		rootOnStart: true,
		delimiter: '.',
		restoreHistoryOnStart: false,
		callback: null,
	};

	const { subscribe, set } = writable([]);

	let _targetDepth = -1;
	let _restoreSegments = [];

	function _segmentArr() {
		return get(routes).map(seg => seg.segment);
	}

	function _segmentStr(segments) {
		if (segments === undefined) {
			segments = _segmentArr();
		}
		return `${segments.length > 0 ? '/' : ''}${segments.join('/')}`;
	}

	function _hash() {
		return location.pathname.replace(/\/?/, '');
	}

	function _segments() {
		return _hash()
			.split('/')
			.filter(r => r !== '');
	}

	function replaceWithoutCallback(url) {
		window.history.replaceState({}, 'mode', url);
	}

	function replaceLast(segment, withHistory = false) {
		const segments = _segmentArr();
		segments.pop();
		segments.push(segment);
		const url = _segmentStr(segments);
		_moveState(url, withHistory);
		parsingSegments();
	}

	function push(segment, segments) {
		let targetStr = _segmentStr(segments);
		targetStr += `/${segment}`;
		_moveState(targetStr, true);
	}

	function pop() {
		window.history.back();
	}

	function depth(targetDepth = 0) {
		_targetDepth = targetDepth;
		parsingSegments();
	}

	function go(segment, withHistory = false) {
		if (typeof withHistory !== 'boolean') {
			withHistory = false; // 기본값
		}
		let toRoot = false;
		if (segment.charAt(0) === '/') {
			toRoot = true;
			segment = segment.replace('/', '');
		}
		if (toRoot) {
			// 현재 어느 깊이에 있든, 루트로 히스토리를 정리하고, 다시 이동한다.
			const url = _segmentStr([segment]);
			_moveState(url, withHistory);
			return false;
		}

		const lastSegment = _segments().pop();
		if (
			lastSegment !== undefined &&
			lastSegment.split(opts.delimiter)[0] ===
				segment.split(opts.delimiter)[0]
		) {
			// 컴포넌트명을 비교하여
			replaceLast(segment, withHistory); // 더 깊게 들어가지 않고 현재 깊이에서 대체
		} else {
			push(segment);
		}
	}

	function parsingSegments() {
		const segments = _segments();
		if (_targetDepth > -1) {
			if (segments.length > _targetDepth) {
				history.back();
				return false;
			} else {
				_targetDepth = -1;
			}
		}

		if (_restoreSegments.length > 0) {
			const segment = _restoreSegments.shift();
			// setTimeout(() => {
			push(segment, segments);
			// }, 300);
			return false;
		}

		// 현재의 URL을 기준으로 segments에 데이터를 넣어주기
		const refinedSegments = segments.map(segment => {
			const tmp = segment.split(opts.delimiter);
			const component = tmp.shift();
			const params = tmp.slice();
			return {
				component,
				segment,
				params,
			};
		});

		set(refinedSegments);

		if (typeof opts.callback === 'function') {
			opts.callback(refinedSegments);
		}
	}

	function reset() {
		_moveState('/', false);
	}

	function init(_opts) {
		Object.keys(_opts).forEach(k => {
			opts[k] = _opts[k];
		});

		const hash = _hash();

		reset(); // 유지해도 리셋은 무조건 한 번 함

		// 뒤로가기가 가능하도록 현재의 상태를 초기화하고 재설정해준다
		// 이것을 안해주면 depth(n) 으로 이동시 무한 뒤로 갈 수가 있음
		// if (!opts.rootOnStart) {
		// 	// 하나씩 돌면서 push해주기
		// 	const segments = hash
		// 		.split('/')
		// 		.filter(r => r !== '')
		// 		.forEach(segment => {
		// 			_restoreSegments.push(segment);
		// 		});
		// }

		parsingSegments();
	}

	function _moveState(url, withHistory) {
		const currentDepth = _segments().length;
		const newDepth = url
			.replace(/\/?/, '')
			.split('/')
			.filter(r => r !== '').length;
		if (currentDepth !== newDepth) {
			withHistory = true;
		}
		window.history[withHistory ? 'pushState' : 'replaceState'](
			{},
			'mode',
			url
		);
		parsingSegments();
	}

	return {
		subscribe,

		init,
		parsingSegments,
		push,
		pop,
		replaceLast,
		depth,
		go,
		replaceWithoutCallback,
	};
})();

export default routes;

if (window.routes === undefined) {
	window.routes = routes;
	window.addEventListener('popstate', () => {
		routes.parsingSegments();
	});
	// document.addEventListener(
	// 	'click',
	// 	function(e) {
	// 		console.log('e.target', e.target);
	// 		if (e.target.classList.contains('route-link')) {
	// 			window.routes.go(e.target.getAttribute('href'));
	// 			e.preventDefault();
	// 		}
	// 	},
	// 	false
	// );
}
