/* components/Loader.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	svg_element
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-310qvu-style";
	style.textContent = ".spinner.svelte-310qvu{-webkit-animation:svelte-310qvu-rotation 1.35s linear infinite;animation:svelte-310qvu-rotation 1.35s linear infinite}@-webkit-keyframes svelte-310qvu-rotation{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(270deg);transform:rotate(270deg)}}@keyframes svelte-310qvu-rotation{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(270deg);transform:rotate(270deg)}}.circle.svelte-310qvu{stroke-dasharray:180;stroke-dashoffset:0;-webkit-transform-origin:center;-ms-transform-origin:center;transform-origin:center;-webkit-animation:svelte-310qvu-turn 1.35s ease-in-out infinite;animation:svelte-310qvu-turn 1.35s ease-in-out infinite}@-webkit-keyframes svelte-310qvu-turn{0%{stroke-dashoffset:180}50%{stroke-dashoffset:45;-webkit-transform:rotate(135deg);transform:rotate(135deg)}100%{stroke-dashoffset:180;-webkit-transform:rotate(450deg);transform:rotate(450deg)}}@keyframes svelte-310qvu-turn{0%{stroke-dashoffset:180}50%{stroke-dashoffset:45;-webkit-transform:rotate(135deg);transform:rotate(135deg)}100%{stroke-dashoffset:180;-webkit-transform:rotate(450deg);transform:rotate(450deg)}}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let svg;
	let circle;
	let svg_width_value;
	let svg_height_value;

	return {
		c() {
			svg = svg_element("svg");
			circle = svg_element("circle");
			attr(circle, "class", "circle svelte-310qvu");
			attr(circle, "fill", "none");
			attr(circle, "stroke-width", "6");
			attr(circle, "stroke-linecap", "round");
			attr(circle, "cx", "33");
			attr(circle, "cy", "33");
			attr(circle, "r", "30");
			attr(svg, "class", "spinner svelte-310qvu");
			attr(svg, "width", svg_width_value = "" + (/*width*/ ctx[1] + "px"));
			attr(svg, "height", svg_height_value = "" + (/*height*/ ctx[2] + "px"));
			attr(svg, "viewBox", "0 0 66 66");
			set_style(svg, "stroke", /*color*/ ctx[0]);
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, circle);
		},
		p(ctx, [dirty]) {
			if (dirty & /*width*/ 2 && svg_width_value !== (svg_width_value = "" + (/*width*/ ctx[1] + "px"))) {
				attr(svg, "width", svg_width_value);
			}

			if (dirty & /*height*/ 4 && svg_height_value !== (svg_height_value = "" + (/*height*/ ctx[2] + "px"))) {
				attr(svg, "height", svg_height_value);
			}

			if (dirty & /*color*/ 1) {
				set_style(svg, "stroke", /*color*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { color = "#fff" } = $$props;
	let { width = 25 } = $$props;
	let { height = 25 } = $$props;

	$$self.$$set = $$props => {
		if ("color" in $$props) $$invalidate(0, color = $$props.color);
		if ("width" in $$props) $$invalidate(1, width = $$props.width);
		if ("height" in $$props) $$invalidate(2, height = $$props.height);
	};

	return [color, width, height];
}

class Loader extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-310qvu-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { color: 0, width: 1, height: 2 });
	}
}

export default Loader;