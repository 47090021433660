/* components/Toast.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	create_in_transition,
	create_out_transition,
	detach,
	element,
	group_outros,
	init,
	insert,
	outro_and_destroy_block,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

import { onMount } from "svelte";
import { fade, fly } from "svelte/transition";
import { backOut } from "svelte/easing";

function add_css() {
	var style = element("style");
	style.id = "svelte-1giihz0-style";
	style.textContent = ".toast-wrapper.svelte-1giihz0{position:fixed;left:0;right:0;bottom:20px;text-align:center;z-index:999999999}.toast-item.svelte-1giihz0{border-radius:4px;margin:5px auto;padding:12px 10px;background:var(--txt-1);color:var(--bg-1);text-align:center;line-height:1.4;max-width:98%;min-width:240px;display:inline-block;opacity:0.9}.toast-item.type-error.svelte-1giihz0{background:var(--danger);color:#fff}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (56:1) {#each toasts as toast (toast)}
function create_each_block(key_1, ctx) {
	let div;
	let t0_value = /*toast*/ ctx[2].msg + "";
	let t0;
	let div_class_value;
	let div_intro;
	let div_outro;
	let t1;
	let br;
	let current;

	return {
		key: key_1,
		first: null,
		c() {
			div = element("div");
			t0 = text(t0_value);
			t1 = space();
			br = element("br");
			attr(div, "class", div_class_value = "toast-item type-" + /*toast*/ ctx[2].type + " svelte-1giihz0");
			this.first = div;
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t0);
			insert(target, t1, anchor);
			insert(target, br, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if ((!current || dirty & /*toasts*/ 1) && t0_value !== (t0_value = /*toast*/ ctx[2].msg + "")) set_data(t0, t0_value);

			if (!current || dirty & /*toasts*/ 1 && div_class_value !== (div_class_value = "toast-item type-" + /*toast*/ ctx[2].type + " svelte-1giihz0")) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (div_outro) div_outro.end(1);

				if (!div_intro) div_intro = create_in_transition(div, fly, {
					delay: 0,
					duration: 300,
					x: 0,
					y: 50,
					opacity: 0.1,
					easing: backOut
				});

				div_intro.start();
			});

			current = true;
		},
		o(local) {
			if (div_intro) div_intro.invalidate();
			div_outro = create_out_transition(div, fade, { duration: 500, opacity: 0 });
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching && div_outro) div_outro.end();
			if (detaching) detach(t1);
			if (detaching) detach(br);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let current;
	let each_value = /*toasts*/ ctx[0];
	const get_key = ctx => /*toast*/ ctx[2];

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "toast-wrapper svelte-1giihz0");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*toasts*/ 1) {
				each_value = /*toasts*/ ctx[0];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div, outro_and_destroy_block, create_each_block, null, get_each_context);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let toasts = [];

	const addToast = (msg = "", type = "normal") => {
		if (type === "error" && msg === "") {
			msg = "The requested operation failed";
		}

		console.log("addToast", msg);
		$$invalidate(0, toasts = [...toasts, { msg, type }]);

		setTimeout(
			() => {
				$$invalidate(0, toasts = toasts.filter((a, i) => i > 0));
			},
			3500
		);
	};

	onMount(() => {
		window.addToast = addToast;
	});

	return [toasts];
}

class Toast extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1giihz0-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Toast;