/* components/App.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	globals,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

const { window: window_1 } = globals;
import { onMount } from "svelte";
import { pusher, logout, tokenCtrl, lockPage } from "~/components/store.js";

function create_fragment(ctx) {
	let div1;
	let input;
	let t0;
	let div0;
	let video;
	let video_src_value;
	let t1;
	let img;
	let img_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			input = element("input");
			t0 = space();
			div0 = element("div");
			video = element("video");
			t1 = space();
			img = element("img");
			attr(input, "type", "file");
			attr(input, "accept", "mp4,mov,jpg,jpeg,png,gif,bmp");
			set_style(input, "font-size", "3rem");
			video.muted = true;
			video.controls = true;
			video.loop = true;
			video.autoplay = true;
			if (video.src !== (video_src_value = /*videoSrc*/ ctx[0])) attr(video, "src", video_src_value);

			set_style(video, "visibility", /*$lockPage*/ ctx[2] || /*videoSrc*/ ctx[0] === ""
			? "hidden"
			: "visible");

			if (img.src !== (img_src_value = /*imageSrc*/ ctx[1])) attr(img, "src", img_src_value);

			set_style(img, "visibility", /*$lockPage*/ ctx[2] || /*imageSrc*/ ctx[1] === ""
			? "hidden"
			: "visible");

			attr(div1, "class", "p-5");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, input);
			append(div1, t0);
			append(div1, div0);
			append(div0, video);
			append(div0, t1);
			append(div0, img);

			if (!mounted) {
				dispose = [
					listen(window_1, "hashchange", /*handleHashchange*/ ctx[4]),
					listen(input, "change", /*handleVideoUpload*/ ctx[3])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*videoSrc*/ 1 && video.src !== (video_src_value = /*videoSrc*/ ctx[0])) {
				attr(video, "src", video_src_value);
			}

			if (dirty & /*$lockPage, videoSrc*/ 5) {
				set_style(video, "visibility", /*$lockPage*/ ctx[2] || /*videoSrc*/ ctx[0] === ""
				? "hidden"
				: "visible");
			}

			if (dirty & /*imageSrc*/ 2 && img.src !== (img_src_value = /*imageSrc*/ ctx[1])) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*$lockPage, imageSrc*/ 6) {
				set_style(img, "visibility", /*$lockPage*/ ctx[2] || /*imageSrc*/ ctx[1] === ""
				? "hidden"
				: "visible");
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $lockPage;
	component_subscribe($$self, lockPage, $$value => $$invalidate(2, $lockPage = $$value));
	let videoSrc = "";
	let imageSrc = "";

	const handleVideoUpload = event => {
		var file = event.target.files[0];
		var type = file.type;
		var URL = window.URL || window.webkitURL;

		if (type.includes("video")) {
			var videoNode = document.querySelector("video");
			var canPlay = videoNode.canPlayType(type);
			console.log("Can play type \"" + type + "\": " + canPlay);

			if (canPlay === "") {
				return;
			}

			$$invalidate(0, videoSrc = URL.createObjectURL(file));
			$$invalidate(1, imageSrc = "");
		} else {
			$$invalidate(0, videoSrc = "");
			$$invalidate(1, imageSrc = URL.createObjectURL(file));
		}
	};

	function handleHashchange() {
		pusher.unsubscribe();
		tokenCtrl.setFromHash();
		pusher.subscribe();
	}

	onMount(() => {
		pusher.subscribe();

		return () => {
			pusher.unsubscribe();
		};
	});

	return [videoSrc, imageSrc, $lockPage, handleVideoUpload, handleHashchange];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;