/* components/Download.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	space
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-zpbr6z-style";
	style.textContent = "#wrapper.svelte-zpbr6z{position:fixed;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;background:var(--primary)}h1.svelte-zpbr6z{color:#fff;font-size:2rem;font-weight:bold;margin-bottom:2rem}p.svelte-zpbr6z{text-align:center;max-width:400px;padding:0 20px;margin-bottom:2rem;color:#fff}button.svelte-zpbr6z{border:1px solid #fff;color:#fff;margin:0.5rem}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div1;
	let h1;
	let t1;
	let p;
	let t3;
	let div0;
	let button0;
	let t5;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			h1 = element("h1");
			h1.textContent = "🎉 Welcome to Signage 🎉";
			t1 = space();
			p = element("p");
			p.textContent = "This app provides your own signage everywhere.\n\t\tPlease select your preferred mode below.";
			t3 = space();
			div0 = element("div");
			button0 = element("button");
			button0.textContent = "💾 Download";
			t5 = space();
			button1 = element("button");
			button1.textContent = "🌐 Try It Online";
			attr(h1, "class", "svelte-zpbr6z");
			attr(p, "class", "svelte-zpbr6z");
			attr(button0, "class", "btn svelte-zpbr6z");
			attr(button1, "class", "btn svelte-zpbr6z");
			attr(div1, "id", "wrapper");
			attr(div1, "class", "svelte-zpbr6z");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, h1);
			append(div1, t1);
			append(div1, p);
			append(div1, t3);
			append(div1, div0);
			append(div0, button0);
			append(div0, t5);
			append(div0, button1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", function () {
						if (is_function(/*handleDownload*/ ctx[0])) /*handleDownload*/ ctx[0].apply(this, arguments);
					}),
					listen(button1, "click", function () {
						if (is_function(/*handleTry*/ ctx[1])) /*handleTry*/ ctx[1].apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { handleDownload } = $$props;
	let { handleTry } = $$props;

	$$self.$$set = $$props => {
		if ("handleDownload" in $$props) $$invalidate(0, handleDownload = $$props.handleDownload);
		if ("handleTry" in $$props) $$invalidate(1, handleTry = $$props.handleTry);
	};

	return [handleDownload, handleTry];
}

class Download extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-zpbr6z-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { handleDownload: 0, handleTry: 1 });
	}
}

export default Download;