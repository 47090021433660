/* components/BlockPage.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_bidirectional_transition,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import Loader from "./Loader.svelte";
import { isBlockPage } from "~/components/store.js";
import { fade } from "svelte/transition";

function add_css() {
	var style = element("style");
	style.id = "svelte-15jgq9x-style";
	style.textContent = ".block-page.svelte-15jgq9x.svelte-15jgq9x{position:fixed;left:0;top:0;right:0;bottom:0;z-index:9999999;background:rgba(0, 0, 0, 0.1)}.block-page.svelte-15jgq9x div.svelte-15jgq9x{position:fixed;top:50%;left:50%;padding:2.3rem 3rem;border-radius:2px;transform:translate3d(-50%, -50%, 0);background:var(--bg-popup);box-shadow:0 0 5px rgba(0, 0, 0, 0.1)}";
	append(document.head, style);
}

// (30:0) {#if $isBlockPage}
function create_if_block(ctx) {
	let div1;
	let div0;
	let loader;
	let div1_transition;
	let current;

	loader = new Loader({
			props: { width: 35, height: 35, color: "#2d87ea" }
		});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(loader.$$.fragment);
			attr(div0, "class", "svelte-15jgq9x");
			attr(div1, "class", "block-page svelte-15jgq9x");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(loader, div0, null);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(loader.$$.fragment, local);

			add_render_callback(() => {
				if (!div1_transition) div1_transition = create_bidirectional_transition(div1, fade, { duration: 500, opacity: 0.5 }, true);
				div1_transition.run(1);
			});

			current = true;
		},
		o(local) {
			transition_out(loader.$$.fragment, local);
			if (!div1_transition) div1_transition = create_bidirectional_transition(div1, fade, { duration: 500, opacity: 0.5 }, false);
			div1_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(loader);
			if (detaching && div1_transition) div1_transition.end();
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*$isBlockPage*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$isBlockPage*/ ctx[0]) {
				if (if_block) {
					if (dirty & /*$isBlockPage*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $isBlockPage;
	component_subscribe($$self, isBlockPage, $$value => $$invalidate(0, $isBlockPage = $$value));
	return [$isBlockPage];
}

class BlockPage extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-15jgq9x-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default BlockPage;