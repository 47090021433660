/* components/Checkbox.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	check_outros,
	create_bidirectional_transition,
	create_slot,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out,
	update_slot
} from "svelte/internal";

import { createEventDispatcher } from "svelte";
import { scale } from "svelte/transition";

function create_else_block(ctx) {
	let i;

	return {
		c() {
			i = element("i");
			i.textContent = "check_box_outline_blank";
			attr(i, "class", "material-icons off");
		},
		m(target, anchor) {
			insert(target, i, anchor);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(i);
		}
	};
}

// (21:2) {#if checked}
function create_if_block(ctx) {
	let i;
	let i_transition;
	let current;

	return {
		c() {
			i = element("i");
			i.textContent = "check_box";
			attr(i, "class", "material-icons on");
		},
		m(target, anchor) {
			insert(target, i, anchor);
			current = true;
		},
		i(local) {
			if (current) return;

			add_render_callback(() => {
				if (!i_transition) i_transition = create_bidirectional_transition(i, scale, { duration: 100, opacity: 0.5, start: 0.2 }, true);
				i_transition.run(1);
			});

			current = true;
		},
		o(local) {
			if (!i_transition) i_transition = create_bidirectional_transition(i, scale, { duration: 100, opacity: 0.5, start: 0.2 }, false);
			i_transition.run(0);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(i);
			if (detaching && i_transition) i_transition.end();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let span;
	let current_block_type_index;
	let if_block;
	let t;
	let div_class_value;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*checked*/ ctx[0]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], null);

	return {
		c() {
			div = element("div");
			span = element("span");
			if_block.c();
			t = space();
			if (default_slot) default_slot.c();
			attr(span, "class", "check");
			attr(div, "class", div_class_value = "material-custom-checkbox " + (/*checked*/ ctx[0] ? "checked" : ""));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);
			if_blocks[current_block_type_index].m(span, null);
			append(div, t);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen(div, "click", /*handleChange*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					
				}

				transition_in(if_block, 1);
				if_block.m(span, null);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 8)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[3], !current ? -1 : dirty, null, null);
				}
			}

			if (!current || dirty & /*checked*/ 1 && div_class_value !== (div_class_value = "material-custom-checkbox " + (/*checked*/ ctx[0] ? "checked" : ""))) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if_blocks[current_block_type_index].d();
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { currentValue = false } = $$props;
	const dispatch = createEventDispatcher();

	function handleChange() {
		dispatch("change", !checked);
	}

	let checked = false;

	$$self.$$set = $$props => {
		if ("currentValue" in $$props) $$invalidate(2, currentValue = $$props.currentValue);
		if ("$$scope" in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*currentValue*/ 4) {
			$: {
				$$invalidate(0, checked = currentValue === true);
			}
		}
	};

	return [checked, handleChange, currentValue, $$scope, slots];
}

class Checkbox extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { currentValue: 2 });
	}
}

export default Checkbox;