import jQuery from 'jquery';
window.jQuery = jQuery;
import Popper from 'popper.js';
window.Popper = Popper;

import Index from '~/components/Index.svelte';

const app = new Index({
	target: document.body,
});

// window.app = app;

export default app;

