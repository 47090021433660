/* components/LockPage.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style
} from "svelte/internal";

import { lockPage } from "~/components/store.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-1yjpn9k-style";
	style.textContent = ".lock-page.svelte-1yjpn9k{position:fixed;left:0;top:0;right:0;bottom:0;z-index:99999999;background:rgba(0, 0, 0, 1)}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "lock-page svelte-1yjpn9k");
			set_style(div, "display", /*$lockPage*/ ctx[0] ? "block" : "none");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$lockPage*/ 1) {
				set_style(div, "display", /*$lockPage*/ ctx[0] ? "block" : "none");
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $lockPage;
	component_subscribe($$self, lockPage, $$value => $$invalidate(0, $lockPage = $$value));
	return [$lockPage];
}

class LockPage extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1yjpn9k-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default LockPage;