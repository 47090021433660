/* components/Login.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	globals,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	null_to_empty,
	prevent_default,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	to_number,
	transition_in,
	transition_out
} from "svelte/internal";

const { document: document_1 } = globals;
import { send, tokenCtrl } from "./store.js";
import Checkbox from "./Checkbox.svelte";
import Loader from "./Loader.svelte";

function add_css() {
	var style = element("style");
	style.id = "svelte-1eag5gq-style";
	style.textContent = "#login-form.svelte-1eag5gq.svelte-1eag5gq{max-width:300px;margin:auto;height:100vh;display:flex;flex-direction:column;justify-content:center}#login-form.svelte-1eag5gq h1.svelte-1eag5gq{text-align:center;margin-bottom:20px}#login-form.svelte-1eag5gq form.svelte-1eag5gq{min-height:220px}";
	append(document_1.head, style);
}

// (171:2) {:else}
function create_else_block_1(ctx) {
	let div;
	let label;
	let t0;
	let t1;
	let t2;
	let input;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			label = element("label");
			t0 = text("Insert 4 digits you received via ");
			t1 = text(/*method*/ ctx[2]);
			t2 = space();
			input = element("input");
			attr(label, "for", "pin-number");
			attr(input, "id", "pin-number");
			attr(input, "type", "number");
			attr(input, "class", "form-control");
			input.autofocus = true;
			input.required = true;
			attr(input, "pattern", "[0-9]*");
			attr(input, "minlength", "4");
			attr(input, "maxlength", "4");
			attr(input, "placeholder", "xxxx");
			attr(div, "class", "form-group");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, label);
			append(label, t0);
			append(label, t1);
			append(div, t2);
			append(div, input);
			set_input_value(input, /*pin*/ ctx[4]);
			input.focus();

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler_2*/ ctx[12]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*method*/ 4) set_data(t1, /*method*/ ctx[2]);

			if (dirty & /*pin*/ 16 && to_number(input.value) !== /*pin*/ ctx[4]) {
				set_input_value(input, /*pin*/ ctx[4]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (122:2) {#if requestedToken === ''}
function create_if_block(ctx) {
	let div1;
	let div0;
	let button0;
	let t0;
	let button0_class_value;
	let t1;
	let button1;
	let t2;
	let button1_class_value;
	let t3;
	let div2;
	let t4;
	let div3;
	let checkbox;
	let current;
	let mounted;
	let dispose;

	function select_block_type_1(ctx, dirty) {
		if (/*method*/ ctx[2] === "email") return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block = current_block_type(ctx);

	checkbox = new Checkbox({
			props: {
				currentValue: /*remember*/ ctx[6],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	checkbox.$on("change", /*handleRemember*/ ctx[7]);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			button0 = element("button");
			t0 = text("Email");
			t1 = space();
			button1 = element("button");
			t2 = text("SMS");
			t3 = space();
			div2 = element("div");
			if_block.c();
			t4 = space();
			div3 = element("div");
			create_component(checkbox.$$.fragment);
			attr(button0, "type", "button");
			button0.value = "email";
			attr(button0, "class", button0_class_value = "btn btn-outline-secondary " + (/*method*/ ctx[2] === "email" ? "active" : ""));
			attr(button1, "type", "button");
			button1.value = "sms";
			attr(button1, "class", button1_class_value = "btn btn-outline-secondary " + (/*method*/ ctx[2] === "sms" ? "active" : ""));
			attr(div0, "class", "btn-group category-tabs");
			attr(div0, "role", "group");
			attr(div1, "class", "form-group");
			attr(div2, "class", "form-group");
			attr(div3, "class", "form-group");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, button0);
			append(button0, t0);
			append(div0, t1);
			append(div0, button1);
			append(button1, t2);
			insert(target, t3, anchor);
			insert(target, div2, anchor);
			if_block.m(div2, null);
			insert(target, t4, anchor);
			insert(target, div3, anchor);
			mount_component(checkbox, div3, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*changeMethod*/ ctx[9]),
					listen(button1, "click", /*changeMethod*/ ctx[9])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (!current || dirty & /*method*/ 4 && button0_class_value !== (button0_class_value = "btn btn-outline-secondary " + (/*method*/ ctx[2] === "email" ? "active" : ""))) {
				attr(button0, "class", button0_class_value);
			}

			if (!current || dirty & /*method*/ 4 && button1_class_value !== (button1_class_value = "btn btn-outline-secondary " + (/*method*/ ctx[2] === "sms" ? "active" : ""))) {
				attr(button1, "class", button1_class_value);
			}

			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div2, null);
				}
			}

			const checkbox_changes = {};
			if (dirty & /*remember*/ 64) checkbox_changes.currentValue = /*remember*/ ctx[6];

			if (dirty & /*$$scope*/ 65536) {
				checkbox_changes.$$scope = { dirty, ctx };
			}

			checkbox.$set(checkbox_changes);
		},
		i(local) {
			if (current) return;
			transition_in(checkbox.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(checkbox.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (detaching) detach(t3);
			if (detaching) detach(div2);
			if_block.d();
			if (detaching) detach(t4);
			if (detaching) detach(div3);
			destroy_component(checkbox);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (153:3) {:else}
function create_else_block(ctx) {
	let input;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			attr(input, "type", "tel");
			attr(input, "class", "form-control");
			input.autofocus = true;
			input.required = true;
			attr(input, "placeholder", "010-0000-0000");
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*receiver*/ ctx[3]);
			input.focus();

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler_1*/ ctx[11]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*receiver*/ 8) {
				set_input_value(input, /*receiver*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

// (144:3) {#if method === 'email'}
function create_if_block_1(ctx) {
	let input;
	let mounted;
	let dispose;

	return {
		c() {
			input = element("input");
			attr(input, "type", "email");
			attr(input, "class", "form-control");
			input.autofocus = true;
			input.required = true;
			attr(input, "placeholder", "your@example.com");
		},
		m(target, anchor) {
			insert(target, input, anchor);
			set_input_value(input, /*receiver*/ ctx[3]);
			input.focus();

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler*/ ctx[10]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*receiver*/ 8 && input.value !== /*receiver*/ ctx[3]) {
				set_input_value(input, /*receiver*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			mounted = false;
			dispose();
		}
	};
}

// (166:3) <Checkbox currentValue="{remember}" on:change="{handleRemember}"     >
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Remember this information");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let h1;
	let t1;
	let form;
	let current_block_type_index;
	let if_block;
	let t2;
	let div0;
	let button;
	let t3;
	let t4;
	let loader;
	let form_class_value;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block, create_else_block_1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*requestedToken*/ ctx[0] === "") return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	loader = new Loader({});

	return {
		c() {
			div1 = element("div");
			h1 = element("h1");
			h1.textContent = "Sign In";
			t1 = space();
			form = element("form");
			if_block.c();
			t2 = space();
			div0 = element("div");
			button = element("button");
			t3 = text(/*btnMsg*/ ctx[5]);
			t4 = space();
			create_component(loader.$$.fragment);
			attr(h1, "class", "svelte-1eag5gq");
			attr(button, "type", "submit");
			attr(button, "class", "btn btn-raised btn-primary btn-block");
			attr(div0, "class", "form-group");
			attr(form, "class", form_class_value = "" + (null_to_empty(/*isAuthorizing*/ ctx[1] ? "now-loading" : "") + " svelte-1eag5gq"));
			attr(div1, "id", "login-form");
			attr(div1, "class", "svelte-1eag5gq");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, h1);
			append(div1, t1);
			append(div1, form);
			if_blocks[current_block_type_index].m(form, null);
			append(form, t2);
			append(form, div0);
			append(div0, button);
			append(button, t3);
			append(button, t4);
			mount_component(loader, button, null);
			current = true;

			if (!mounted) {
				dispose = listen(form, "submit", prevent_default(/*handleSubmit*/ ctx[8]));
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(form, t2);
			}

			if (!current || dirty & /*btnMsg*/ 32) set_data(t3, /*btnMsg*/ ctx[5]);

			if (!current || dirty & /*isAuthorizing*/ 2 && form_class_value !== (form_class_value = "" + (null_to_empty(/*isAuthorizing*/ ctx[1] ? "now-loading" : "") + " svelte-1eag5gq"))) {
				attr(form, "class", form_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(loader.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(loader.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if_blocks[current_block_type_index].d();
			destroy_component(loader);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const cahchedReceiver = localStorage.receiver || "";
	let isAuthorizing = false;
	let requestedToken = "";

	let method = cahchedReceiver.includes("@") || cahchedReceiver === ""
	? "email"
	: "sms";

	let receiver = localStorage.receiver;
	let pin = "";
	let btnMsg = "";
	let remember = cahchedReceiver !== "";

	function handleRemember(e) {
		console.log("e.detail", e.detail);
		$$invalidate(6, remember = e.detail);
	}

	function handleSubmit() {
		if (requestedToken === "") {
			requestToken();
		} else {
			checkPin();
		}
	}

	function requestToken() {
		console.log("requestToken");

		if (isAuthorizing) {
			return false;
		}

		$$invalidate(1, isAuthorizing = true);

		// set({ ..._login, isAuthorizing: true });
		// $dialog.preloader();
		send({
			type: "post",
			url: "/token/request",
			data: { type: method, address: receiver },
			success: json => {
				console.log("success");

				if (remember) {
					localStorage.receiver = receiver;
				}

				// $dialog.close();
				$$invalidate(1, isAuthorizing = false);

				if (json.status === "done") {
					$$invalidate(0, requestedToken = json.token);
				} else {
					window.addToast("The requested operation failed", "error"); // this.pin.focus();
				}
			}
		});
	}

	function checkPin() {
		if (isAuthorizing) {
			return false;
		}

		$$invalidate(1, isAuthorizing = true);

		send({
			type: "post",
			url: "/token/pin_check",
			data: { token: requestedToken, pin },
			success: json => {
				$$invalidate(1, isAuthorizing = false);

				if (json.status === "done") {
					// isBlockPage.set(false);
					tokenCtrl.save(json);
				} else {
					window.addToast("Invalid code", "error");
				}
			}
		});
	}

	function changeMethod(e) {
		$$invalidate(2, method = e.target.value);

		setTimeout(
			() => {
				document.querySelector("form input").focus();
			},
			1
		);
	}

	function input_input_handler() {
		receiver = this.value;
		$$invalidate(3, receiver);
	}

	function input_input_handler_1() {
		receiver = this.value;
		$$invalidate(3, receiver);
	}

	function input_input_handler_2() {
		pin = to_number(this.value);
		$$invalidate(4, pin);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*requestedToken*/ 1) {
			// computed value
			$: {
				$$invalidate(5, btnMsg = requestedToken === "" ? "Request Code" : "Confirm");
			}
		}
	};

	return [
		requestedToken,
		isAuthorizing,
		method,
		receiver,
		pin,
		btnMsg,
		remember,
		handleRemember,
		handleSubmit,
		changeMethod,
		input_input_handler,
		input_input_handler_1,
		input_input_handler_2
	];
}

class Login extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-1eag5gq-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Login;