/* components/PwaPopup.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

import localforage from "localforage";

function add_css() {
	var style = element("style");
	style.id = "svelte-asvwfq-style";
	style.textContent = "@keyframes svelte-asvwfq-updown{from{transform:translate3d(0, 3px, 0)}to{transform:translate3d(0, -3px, 0)}}.pwa-popup-wrapper.svelte-asvwfq.svelte-asvwfq{background:rgba(0, 0, 0, 0.7);color:#fff;border-radius:5px;position:fixed;z-index:9999;min-width:240px;border:1px solid rgba(255, 255, 255, 0.15)}.pwa-popup-wrapper.ios.svelte-asvwfq.svelte-asvwfq{left:50%;bottom:10px;transform:translate3d(-50%, 0, 0);padding:12px 30px 45px 17px}.pwa-popup-wrapper.ios.svelte-asvwfq .pwa-popup-arrow.svelte-asvwfq{bottom:10px;left:50%;margin-left:-5px}.pwa-popup-wrapper.android.svelte-asvwfq.svelte-asvwfq{font-size:20px;position:absolute;top:10px;right:10px;padding:17px 40px 17px 20px}.pwa-popup-wrapper.android.svelte-asvwfq .pwa-popup-arrow.svelte-asvwfq{right:7px;top:7px}.pwa-popup-wrapper.android.svelte-asvwfq .pwa-popup-arrow span.svelte-asvwfq{display:inline-block;transform:rotate(180deg)}.pwa-popup-msg.svelte-asvwfq.svelte-asvwfq{font-size:15px;font-weight:bold;text-align:center}.pwa-popup-arrow.svelte-asvwfq.svelte-asvwfq{font-size:24px;position:absolute;animation:svelte-asvwfq-updown 0.7s linear infinite alternate}";
	append(document.head, style);
}

// (90:0) {#if visible}
function create_if_block(ctx) {
	let div2;
	let div0;
	let t1;
	let div1;
	let div2_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			div0.textContent = "Add to Your Homescreen";
			t1 = space();
			div1 = element("div");
			div1.innerHTML = `<span class="svelte-asvwfq">↓</span>`;
			attr(div0, "class", "pwa-popup-msg svelte-asvwfq");
			attr(div1, "class", "pwa-popup-arrow svelte-asvwfq");
			attr(div2, "class", div2_class_value = "pwa-popup-wrapper " + /*platform*/ ctx[0] + " svelte-asvwfq");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t1);
			append(div2, div1);

			if (!mounted) {
				dispose = listen(div2, "click", /*click_handler*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*platform*/ 1 && div2_class_value !== (div2_class_value = "pwa-popup-wrapper " + /*platform*/ ctx[0] + " svelte-asvwfq")) {
				attr(div2, "class", div2_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div2);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*visible*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*visible*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const isStandalone = !!window.matchMedia && window.matchMedia("(display-mode: standalone)").matches;
	const isTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0; // works on IE10/11 and Surface
	const isAndorid = (/android/i).test(navigator.userAgent);
	const isIos = (/iphone|ipad/i).test(navigator.userAgent);
	let platform = "";
	let visible = false;

	if (!isStandalone && isTouch) {
		platform = isAndorid ? "android" : isIos ? "ios" : "";

		localforage.getItem("pwa_popup_count").then(value => {
			if (value === null) {
				value = 0;
			} else {
				value = Number(value);
			}

			if (value !== 0 && value % 5 === 0 || value === 1) {
				$$invalidate(1, visible = true);

				setTimeout(
					() => {
						$$invalidate(1, visible = false);
					},
					value === 1 ? 15000 : 7000
				);
			}

			localforage.setItem("pwa_popup_count", ++value);
		});
	}

	const click_handler = () => $$invalidate(1, visible = false);
	return [platform, visible, click_handler];
}

class PwaPopup extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-asvwfq-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default PwaPopup;