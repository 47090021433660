/* components/Index.svelte generated by Svelte v3.38.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { onMount } from "svelte";
import routes from "~/components/routes.js";
import Login from "~/components/Login.svelte";
import Download from "~/components/Download.svelte";
import Toast from "~/components/Toast.svelte";
import BlockPage from "~/components/BlockPage.svelte";
import LockPage from "~/components/LockPage.svelte";
import PwaPopup from "~/components/PwaPopup.svelte";
import App from "~/components/App.svelte";
import { tokenCtrl, pusher } from "~/components/store.js";

function create_fragment(ctx) {
	let pwapopup;
	let t0;
	let main;
	let div;
	let app;
	let t1;
	let blockpage;
	let t2;
	let lockpage;
	let t3;
	let toast;
	let current;
	pwapopup = new PwaPopup({});
	app = new App({});
	blockpage = new BlockPage({});
	lockpage = new LockPage({});
	toast = new Toast({});

	return {
		c() {
			create_component(pwapopup.$$.fragment);
			t0 = space();
			main = element("main");
			div = element("div");
			create_component(app.$$.fragment);
			t1 = space();
			create_component(blockpage.$$.fragment);
			t2 = space();
			create_component(lockpage.$$.fragment);
			t3 = space();
			create_component(toast.$$.fragment);
			attr(div, "id", "app");
		},
		m(target, anchor) {
			mount_component(pwapopup, target, anchor);
			insert(target, t0, anchor);
			insert(target, main, anchor);
			append(main, div);
			mount_component(app, div, null);
			append(div, t1);
			mount_component(blockpage, div, null);
			append(div, t2);
			mount_component(lockpage, div, null);
			append(div, t3);
			mount_component(toast, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(pwapopup.$$.fragment, local);
			transition_in(app.$$.fragment, local);
			transition_in(blockpage.$$.fragment, local);
			transition_in(lockpage.$$.fragment, local);
			transition_in(toast.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pwapopup.$$.fragment, local);
			transition_out(app.$$.fragment, local);
			transition_out(blockpage.$$.fragment, local);
			transition_out(lockpage.$$.fragment, local);
			transition_out(toast.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(pwapopup, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(main);
			destroy_component(app);
			destroy_component(blockpage);
			destroy_component(lockpage);
			destroy_component(toast);
		}
	};
}

function handleDownload() {
	const urls = {
		mac: "https://zidell-web.s3.ap-northeast-2.amazonaws.com/note_installer/note_installer.dmg",
		win32: "https://zidell-web.s3.ap-northeast-2.amazonaws.com/note_installer/note_ia32_setup.exe",
		win64: "https://zidell-web.s3.ap-northeast-2.amazonaws.com/note_installer/note_x64_setup.exe"
	};

	let platform = navigator.platform === "MacIntel" ? "mac" : "win";

	if (platform === "win") {
		alert("- 다운로드시 중단되면 더보기 후 [계속]을 선택하십시오.\n- 설치시 Windows의 PC 보호가 뜨면 [추가정보]로 진행하십시오.");

		platform += navigator.userAgent.toLowerCase().indexOf("win64") > -1
		? "64"
		: "32";
	}

	location.href = urls[platform];
}

function instance($$self, $$props, $$invalidate) {
	let $tokenCtrl;
	component_subscribe($$self, tokenCtrl, $$value => $$invalidate(0, $tokenCtrl = $$value));
	const isDesktop = navigator.userAgent.toLowerCase().indexOf("desktop") > -1 || typeof window.todesktop !== "undefined";
	const isStandalone = !!window.matchMedia && window.matchMedia("(display-mode: standalone)").matches;
	const alreaySelectOnline = sessionStorage.tryitonline !== undefined && !!sessionStorage.tryitonline;
	let showIndex = true;

	if (isDesktop || window.isTouch || isStandalone) {
		showIndex = false;
	}

	function handleTry() {
		sessionStorage.tryitonline = true;
		showIndex = false;
	}

	onMount(() => {
		tokenCtrl.init();

		return () => {
			
		};
	});

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$tokenCtrl*/ 1) {
			$: {
				console.log("$tokenCtrl", $tokenCtrl);
				console.log("$tokenCtrl.token", $tokenCtrl.token);
			}
		}
	};

	return [$tokenCtrl];
}

class Index extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Index;