import localforage from 'localforage';
import Pusher from 'pusher-js';
import jQuery from 'jquery';
import { writable, get } from 'svelte/store';

const isDevelopment = location.hostname === 'localhost';

window.isTouch = (function is_touch_device4() {
	var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

	var mq = function (query) {
		return window.matchMedia(query).matches;
	};

	if (
		'ontouchstart' in window ||
		(window.DocumentTouch && document instanceof DocumentTouch)
	) {
		return true;
	}

	// include the 'heartz' as a way to have a non matching MQ to help terminate the join
	// https://git.io/vznFH
	var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
		''
	);
	return mq(query);
})();
document
	.getElementsByTagName('html')[0]
	.classList.add(window.isTouch ? 'touch' : 'no-touch');

export const isBlockPage = writable(false);

export const lockPage = (() => {
	const { subscribe, set } = writable(false);

	const lock = () => {
		set(true);
	};

	const unlock = () => {
		set(false);
	};

	return {
		subscribe,
		lock,
		unlock
	};
})();

export const tokenCtrl = (() => {
	const setFromHash = () => {
		const hash = location.hash.replace('#', '') || 'example';
		return {
			token: hash,
			channel : hash
		};
	}
	
	const { subscribe, set, update } = writable(setFromHash());

	const init = () => {
		localforage.getItem('serializedToken').then((serializedJson) => {
			console.log('TCL: token -> serializedJson', serializedJson);
			if (serializedJson !== null) {
				// isBlockPage.set(false);
				save(JSON.parse(serializedJson));
			} else {
				save({
					token: '',
					channel: '',
				});
			}
		});
	};

	const save = (json) => {
		// set(json);
		// localforage.setItem('serializedToken', JSON.stringify(json));
	};

	const clear = () => {
		save({
			token: '',
			channel: '',
		});
	}

	return {
		subscribe,
		init,
		setFromHash,
		clear,
		save
	};
})();

export const ratio = (() => {
	const { set } = writable(window.outerHeight / window.innerHeight);

	return {
		set
	};
})();

export const send = (opts, tmpToken) => {
	if (isDevelopment) {
		opts.url = `http://localhost:3000${opts.url
			.replace(/\//g, '--')
			.replace('--', '/')}`;
		opts.type = 'get';
	}
	opts.data = {
		payload: JSON.stringify(opts.data),
	};
	opts.cache = false;
	opts.dataType = 'json';
	opts.error = (err) => {
		console.log('err', err);
		if (err.status === 400) {
			if (confirm('There is an update. Do you want to refresh now?')) {
				location.reload();
			}
		} else if (err.status === 401) {
			alert('The token has expired.');
			isBlockPage.set(false);
			tokenCtrl.clear();
		} else {
			window.addToast('.', 'error');
		}
	};
	const _token = tmpToken !== undefined ? tmpToken : get(tokenCtrl).token;
	if (_token !== null) {
		opts.headers = {
			Version: window.__VERSION,
			Authorization: _token,
		};
	} else {
		// return false;
	}

	jQuery.ajax(opts);
};

export const logout = () => {
	isBlockPage.set(true);
	send({
		type: 'post',
		url: '/token/destroy',
		data: {},
		success: (json) => {
			console.log('success');
			// $dialog.close();
			if (json.status === 'done') {
				isBlockPage.set(false);
				tokenCtrl.clear();
			} else {
				alert('실패');
			}
		},
		fail: (err) => {
			console.log('fail');
		},
	});
};

export const pusher = {
	channel: null,
	subscribe() {
		Pusher.logToConsole = isDevelopment;

		var pusher = new Pusher('7477675c780ed71b3db5', {
			cluster: 'ap3',
			forceTLS: true,
		});

		this.channel = pusher.subscribe(get(tokenCtrl).channel);
		this.channel.bind('update', function (data) {
			console.log('data', data);
			switch (data.action) {
				case 'lockPage':
					lockPage[data.onoff]();
			}
		});
	},

	push(data) {
		send({
			type: 'post',
			url: '/pusher',
			data,
			success: (json) => {
				console.log('success');
			},
			fail: (err) => {
				console.log('fail');
			},
		});
	},

	unsubscribe() {
		this.channel.unsubscribe();
		this.channel = null;
	},
};

(() => {
	window.onerror = (e) => {
		return;
		console.log('e', e);
		if (confirm('An unknown error has occurred. Do you want to refresh?')) {
			location.reload();
		}
	};

	// window.onkeydown = (e) => {
	// 	const isCommandKey = e.metaKey || e.ctrlKey;
	// 	const KEY_BACKARD = 219;
	// 	const KEY_FORWARD = 221;
	// 	const KEY_N = 78;
	// 	const KEY_S = 83;
	// 	const KEY_F = 70;

	// 	let job = '';
	// 	if (isCommandKey) {
	// 		switch (e.keyCode) {
	// 			case KEY_BACKARD:
	// 			case KEY_FORWARD:
	// 				job = 'NOTHING';
	// 				break;
	// 			case KEY_N:
	// 				job = 'CREATE_NOTE';
	// 				break;
	// 			case KEY_F:
	// 				job = 'SEARCH';
	// 				break;
	// 		}
	// 	}

	// 	if (job !== '') {
	// 		e.preventDefault();
	// 		e.stopPropagation();
	// 	}

	// 	switch (job) {
	// 		case 'CREATE_NOTE':
	// 			window.routes.go('/note.creating');
	// 			break;
	// 		case 'SEARCH':
	// 			notes.toggleSearch('key');
	// 			break;
	// 	}
	// };

	window.onkeyup = (e) => {
		const KEY_ESC = 27;
		const KEY_BACKTIC = 192;
		const KEY_SPACE = 32;
		switch (e.keyCode) {
			case KEY_BACKTIC:
				{
					pusher.push({
						channel: get(tokenCtrl).channel,
						action: 'lockPage',
						onoff: 'lock'
					});
				}
				break;
			case KEY_SPACE:
				{
					pusher.push({
						channel: get(tokenCtrl).channel,
						action: 'lockPage',
						onoff: 'unlock'
					});
				}
				break;
		}
	};

	window.onresize = () => {
		const isFullscreen = window.innerHeight === window.outerHeight;
		jQuery('html')[isFullscreen ? 'addClass' : 'removeClass']('fullscreen');
	};
})();
